import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  TemplateRef,
  ViewChild,
  ViewChildDecorator,
} from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import {
  ComponentNamesForUserConfiguration,
  IUserConfiguration,
} from '../../../store/user-configuration/user-configuration.model';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { ActionsSubject, Store } from '@ngrx/store';
import { LogbookAppState } from '../../../store/logbook.reducer';
import { TranslateService } from '@ngx-translate/core';
import { IPageHeader } from '../../service/page-header/page-header.model';
import { Subscription } from 'rxjs';
import { IPageConfiguration } from './page-configuration.model';
import * as _ from 'lodash';
import { ITableHeader, ITableHeaderGroup, PageConfigurationTypes } from '../../../../constants.model';
import { MatCheckboxChange } from '@angular/material/checkbox';
import * as UserSettingsActions from '../../../store/settings/users/users.actions';
import * as LogsFormEntriesActions from '../../../store/my-tasks/logs-form-entries/logs-form-entries.actions';
import * as LogbookTasksActions from '../../../store/my-tasks/logbook-tasks/logbook-tasks.actions';
import * as FormTasksActions from '../../../store/my-tasks/form-tasks/form-tasks.actions';
import * as MasterDataTasksActions from '../../../store/my-tasks/master-data-tasks/master-data-tasks.actions';
import * as HomeActions from '../../../store/home/home.actions';
import * as FormEntriesActions from '../../../store/reports/form-entries/form-entries.actions';
import * as FormTemplateActions from '../../../store/reports/form-templates/form-templates.actions';
import * as LogbookTemplateActions from '../../../store/reports/logbook-templates/logbook-templates.actions';
import * as LogsReportsActions from '../../../store/reports/logs-reports/logs-reports.actions';
import * as PageConfigurationActions from '../../../store/page-configuration/page-configuration.actions';
import * as LogbookActions from '../../../store/logbooks/logbooks.actions';
import * as FormActions from '../../../store/forms/forms.actions';
import { User } from '../../../store/user/model';
import { HelperService } from '../../service/helper.service';
import { UsersService } from '../../../store/settings/users/users.service';
import { OnDestroyDecorator } from '../../decorator/on-destroy-decorator';
import { ActivatedRoute, Router } from '@angular/router';
import { LogsFormEntriesService } from '../../service/my-tasks/logs-form-entries/logs-form-entries.service';
import { LogbookTasksService } from '../../service/my-tasks/logbook-tasks/logbook-tasks.service';
import { FormTasksService } from '../../service/my-tasks/form-tasks/form-tasks.service';
import { HomeService } from '../../../store/home/home.service';
import { MasterDataTasksService } from '../../service/my-tasks/master-data-tasks/master-data-tasks.service';
import { FormEntriesService } from '../../service/reports/form-entries/form-entries.service';
import { LogsReportsService } from '../../../store/reports/logs-reports/logs-reports.service';
import { IClientSettingsState } from '../../../store/settings/client/client.model';
import { IHomeState } from 'src/app/store/home/home.model';
import { IFormVersion } from '../../../store/forms/forms.model';
import { ILogsFormEntriesColumn } from '../../../view/my-tasks/logs-form-entries/logs-form-entries.component.model';
import {
  EPageConfigurationFieldType,
  EPageConfigurationLevel,
  EPageConfigurationLocation,
  IAddPageConfiguration,
  IConfigurationDropdown,
  IConfigurationMeta,
  IFormPageConfiguration,
  IPageConfigurationParams,
} from '../../../store/page-configuration/page-configuration.model';
import { ofType } from '@ngrx/effects';
import { IHomeLogbook } from '../../../view/home/home.model';
import { IPageHeaderState } from '../../../store/page-header/page-header.model';
import { ELogbookAndLogbookVersionTabs } from '../../../store/logbooks/logbooks.model';

@OnDestroyDecorator
@Component({
  selector: 'app-page-configuration',
  templateUrl: './page-configuration.component.html',
  styleUrls: ['./page-configuration.component.scss'],
})
export class PageConfigurationComponent implements OnInit, OnChanges {
  @ViewChild('close_button') closeButtonRef!: ViewChild;
  @ViewChild('unsaved_modal') unsavedChangesModalTemplateRef!: ViewChildDecorator;
  @ViewChild('config_modal') configModalTemplateRef!: ViewChildDecorator;
  @ViewChild('user_home_config_modal') userConfigModalTemplateRef!: ViewChildDecorator;

  @Input() pageHeader: IPageHeader = {
    title: '',
    titleKey: '',
    icon: '',
    fullScreenButton: false,
    fullScreenTargetElementId: '',
  };
  @Input() isChangeLogbookVersion: boolean = false;

  private readonly storeSubscriptions: Subscription[] = [];
  private readonly scwModalSm: string = 'scw-modal-sm';
  private readonly scwModalLg: string = 'scw-modal-lg';
  private readonly scwModalXl: string = 'scw-modal-xl overflow-unset';
  private userId$!: number;
  private clientId$!: number;
  public configModalRef!: NgbModalRef;
  public modalArray: NgbModalRef[] = [];
  public changesMade: boolean = false;
  public forceRemoteColumnLoad: boolean = false;
  public showReturnToMyReportButton: boolean = false;
  public currentTabComponent: string = '';
  public selectedRecords: string[] = [];
  private isSelectOrUnselectAllClicked: boolean = false;

  public isClientConfiguration: boolean = false;
  public userConfigurationData: IUserConfiguration | null = null;
  public logbookHomeConfiguration!: Partial<ITableHeader>[];
  public saveChangedModalRef!: NgbModalRef;
  public tableLabelTranslation: string = this.translate.instant('pageConfiguration.modal.tableLabel');
  public columns: any[] = [];
  public tabsToBeAtTheEnd: ITableHeader[] = [];
  public rows: ITableHeader[] = [];
  public formDropdownData: IConfigurationDropdown[] = [];
  public formFieldsHeader: ITableHeader[] = [];
  public masterDataHeader: ITableHeader[] = [];
  public headerWithFormFields: any;
  public allFormFieldColumns: any;
  public isSelectAllButtonDisabled: boolean = false;
  public isResetButtonDisabled: boolean = false;
  public generalConfigurationArray: ITableHeaderGroup[] = [];
  public selectedForms: IConfigurationDropdown[] | undefined;
  public tabs: IPageConfiguration[] = [
    {
      table: [],
    },
  ];
  public logbookTabs: ITableHeader[] = [];
  public allLogbookVersionTabs: ITableHeader[] = [];
  public initialTabs: IPageConfiguration[] = [
    {
      table: [],
    },
  ];
  public customColumnManagerData$: any[] = [
    {
      name: 'test report view 1',
      published: false,
      createdBy: {
        firstName: 'Engin',
        lastName: 'Ozkaya',
        title: 'Architech',
        email: 'engin@scw.ai',
      },
      ownedByMe: true,
      selected: false,
    },
    {
      name: 'Cleaning Forms Only',
      published: false,
      createdBy: {
        firstName: 'Engin',
        lastName: 'Ozkaya',
        title: 'Architech',
        email: 'engin@scw.ai',
      },
      ownedByMe: true,
      selected: true,
    },
    {
      name: 'test report 2',
      published: true,
      createdBy: {
        firstName: 'Murat',
        lastName: 'Saglam',
        title: 'VP',
        email: 'murat@scw.ai',
      },
      ownedByMe: false,
      selected: false,
    },
  ];
  public customColumnManagerHeaders: ITableHeader[] = [
    {
      value: 'name',
      name: this.translate.instant('pageConfiguration.customManager.headers.name'),
      sortable: false,
    },
    {
      value: 'published',
      name: this.translate.instant('pageConfiguration.customManager.headers.published'),
      sortable: false,
    },
    {
      value: 'createdBy',
      name: this.translate.instant('pageConfiguration.customManager.headers.createdBy'),
      sortable: false,
    },
    {
      value: 'actions',
      name: this.translate.instant('pageConfiguration.customManager.headers.actions'),
      sortable: false,
    },
  ];

  public formTemplatesHeaders: ITableHeader[] = [
    {
      value: 'name',
      name: this.translate.instant('general.datatable.headers.formName'),
      sortable: true,
      draggable: true,
      selected: true,
      type: PageConfigurationTypes.TABLE,
    },
    {
      value: 'formIdFormatted',
      name: this.translate.instant('general.datatable.headers.formId'),
      selected: true,
      sortable: false,
      draggable: true,
      type: PageConfigurationTypes.TABLE,
      disabled: true,
    },
    {
      value: 'workflowDetailFormatted',
      name: this.translate.instant('general.datatable.headers.workflowFormApproval'),
      sortable: true,
      draggable: true,
      selected: true,
      type: PageConfigurationTypes.TABLE,
    },
    {
      value: 'workflowEntriesDetailFormatted',
      name: this.translate.instant('general.datatable.headers.workflowEntries'),
      sortable: true,
      draggable: true,
      selected: true,
      type: PageConfigurationTypes.TABLE,
    },
    {
      value: 'legacyId',
      name: this.translate.instant('general.datatable.headers.formLegacyId'),
      sortable: true,
      draggable: true,
      selected: true,
      type: PageConfigurationTypes.TABLE,
    },
    {
      value: 'activityTypeFormatted',
      name: this.translate.instant('general.datatable.headers.activityType'),
      sortable: true,
      draggable: true,
      selected: true,
      type: PageConfigurationTypes.TABLE,
    },
    {
      value: 'versionNumber',
      name: this.translate.instant('general.datatable.headers.version'),
      sortable: false,
      draggable: true,
      selected: true,
      type: PageConfigurationTypes.TABLE,
    },
    {
      value: 'createdBy',
      name: this.translate.instant('general.datatable.headers.createdBy'),
      sortable: false,
      draggable: true,
      selected: true,
      type: PageConfigurationTypes.TABLE,
    },

    {
      value: 'createdAt',
      name: this.translate.instant('general.datatable.headers.createdAt'),
      sortable: false,
      draggable: true,
      selected: true,
      type: PageConfigurationTypes.TABLE,
    },
    {
      value: 'submittedBy',
      name: this.translate.instant('general.datatable.headers.submittedBy'),
      sortable: false,
      draggable: true,
      selected: true,
      type: PageConfigurationTypes.TABLE,
    },
    {
      value: 'submittedAt',
      name: this.translate.instant('general.datatable.headers.submittedAt'),
      sortable: false,
      draggable: true,
      selected: true,
      type: PageConfigurationTypes.TABLE,
    },
    {
      value: 'approvalStepPosition',
      name: this.translate.instant('general.datatable.headers.status'),
      sortable: false,
      draggable: true,
      selected: true,
      type: PageConfigurationTypes.TABLE,
    },
    {
      value: 'issuedDate',
      name: this.translate.instant('general.datatable.headers.issuedDate'),
      sortable: true,
      draggable: true,
      selected: true,
      type: PageConfigurationTypes.TABLE,
    },
    {
      value: 'actions',
      name: this.translate.instant('general.datatable.headers.content'),
      sortable: false,
      draggable: true,
      selected: true,
      disabled: true,
      type: PageConfigurationTypes.TABLE,
    },
    {
      value: 'userDefinedFields',
      name: this.translate.instant('general.datatable.headers.userDefinedFields'),
      sortable: false,
      draggable: false,
      selected: true,
      alwaysAtTheEnd: true,
      type: PageConfigurationTypes.TABLE,
    },
    {
      value: 'checkInMechanism',
      name: this.translate.instant('general.datatable.headers.checkInMechanism'),
      sortable: true,
      draggable: true,
      selected: true,
      type: PageConfigurationTypes.TABLE,
    },
  ];

  public logbookTemplatesHeaders: ITableHeader[] = [
    {
      value: 'name',
      name: this.translate.instant('general.datatable.headers.logbookName'),
      sortable: true,
      draggable: true,
      selected: true,
      type: PageConfigurationTypes.TABLE,
    },
    {
      value: 'logbookIdFormatted',
      name: this.translate.instant('general.datatable.headers.logbookId'),
      selected: true,
      sortable: false,
      draggable: true,
      type: PageConfigurationTypes.TABLE,
      disabled: true,
    },
    {
      value: 'workflowFormatted',
      name: this.translate.instant('general.datatable.headers.workflowLogbookApproval'),
      sortable: true,
      draggable: true,
      selected: true,
      type: PageConfigurationTypes.TABLE,
    },
    {
      value: 'legacyId',
      name: this.translate.instant('general.datatable.headers.logbookLegacyId'),
      sortable: true,
      draggable: true,
      selected: true,
      type: PageConfigurationTypes.TABLE,
    },
    {
      value: 'mobileLogbookFormatted',
      name: this.translate.instant('general.datatable.headers.mobileLogbook'),
      sortable: true,
      draggable: true,
      selected: true,
      type: PageConfigurationTypes.TABLE,
    },
    {
      value: 'parentLogbookName',
      name: this.translate.instant('general.datatable.headers.parentLogbookName'),
      sortable: true,
      draggable: true,
      selected: true,
      type: PageConfigurationTypes.TABLE,
    },
    {
      value: 'versionNumber',
      name: this.translate.instant('general.datatable.headers.version'),
      sortable: false,
      draggable: true,
      selected: true,
      type: PageConfigurationTypes.TABLE,
    },
    {
      value: 'createdBy',
      name: this.translate.instant('general.datatable.headers.createdBy'),
      sortable: false,
      draggable: true,
      selected: true,
      type: PageConfigurationTypes.TABLE,
    },

    {
      value: 'createdAt',
      name: this.translate.instant('general.datatable.headers.createdAt'),
      sortable: false,
      draggable: true,
      selected: true,
      type: PageConfigurationTypes.TABLE,
    },
    {
      value: 'submittedBy',
      name: this.translate.instant('general.datatable.headers.submittedBy'),
      sortable: false,
      draggable: true,
      selected: true,
      type: PageConfigurationTypes.TABLE,
    },
    {
      value: 'submittedAt',
      name: this.translate.instant('general.datatable.headers.submittedAt'),
      sortable: false,
      draggable: true,
      selected: true,
      type: PageConfigurationTypes.TABLE,
    },
    {
      value: 'approvalStepPosition',
      name: this.translate.instant('general.datatable.headers.status'),
      sortable: false,
      draggable: true,
      selected: true,
      type: PageConfigurationTypes.TABLE,
    },
    {
      value: 'issuedDate',
      name: this.translate.instant('general.datatable.headers.issuedDate'),
      sortable: true,
      draggable: true,
      selected: true,
      type: PageConfigurationTypes.TABLE,
    },
    {
      value: 'actions',
      name: this.translate.instant('general.datatable.headers.content'),
      sortable: false,
      draggable: true,
      selected: true,
      disabled: true,
      type: PageConfigurationTypes.TABLE,
    },
    {
      value: 'stateDurationFormatted',
      name: this.translate.instant('general.datatable.headers.stateDuration'),
      sortable: false,
      draggable: true,
      selected: false,
      type: PageConfigurationTypes.TABLE,
    },
    {
      value: 'userDefinedFields',
      name: this.translate.instant('general.datatable.headers.userDefinedFields'),
      sortable: false,
      draggable: false,
      selected: true,
      alwaysAtTheEnd: true,
      type: PageConfigurationTypes.TABLE,
    },
  ];

  public logbookTableHeaders: ITableHeader[] = [
    {
      value: 'name',
      name: this.translate.instant('general.datatable.headers.logbook'),
      sortable: false,
      draggable: false,
      disabled: true,
      selected: true,
      type: PageConfigurationTypes.TABLE,
    },
    {
      value: 'logbookId',
      name: this.translate.instant('general.datatable.headers.logbookId'),
      sortable: false,
      draggable: true,
      selected: true,
      type: PageConfigurationTypes.TABLE,
    },
    {
      value: 'legacyId',
      name: this.translate.instant('general.datatable.headers.logbookLegacyId'),
      sortable: false,
      draggable: true,
      selected: true,
      type: PageConfigurationTypes.TABLE,
    },
    {
      value: 'versionNumber',
      name: this.translate.instant('general.datatable.headers.versionNumber'),
      sortable: false,
      draggable: true,
      selected: true,
      type: PageConfigurationTypes.TABLE,
    },
    {
      value: 'orderBy',
      name: this.translate.instant('general.datatable.headers.order'),
      sortable: false,
      button: true,
      buttonConfig: {
        type: 'standard',
        iconButton: true,
        changeAbleIcon: true,
        icon: ['fas fa-pen', 'fas fa-save'],
        size: 'xs',
        disabled: true,
        onClick: () => {},
        cancelButtonOnClick: () => {},
      },
      draggable: true,
      selected: true,
      type: PageConfigurationTypes.TABLE,
      interactiveColumn: false,
      showCancelButton: false,
    },
    {
      value: 'issueDateFormatted',
      name: this.translate.instant('general.datatable.headers.issuedDate'),
      sortable: false,
      draggable: true,
      selected: true,
      type: PageConfigurationTypes.TABLE,
    },
    {
      value: 'activatedAtFormatted',
      name: this.translate.instant('general.datatable.headers.activationDate'),
      sortable: false,
      draggable: true,
      selected: true,
      type: PageConfigurationTypes.TABLE,
    },
    {
      value: 'loggableFormatted',
      name: this.translate.instant('general.datatable.headers.loggable'),
      sortable: false,
      isBooleanField: true,
      draggable: true,
      selected: true,
      type: PageConfigurationTypes.TABLE,
    },
    {
      value: 'approvalStepPosition',
      name: this.translate.instant('general.datatable.headers.status'),
      sortable: false,
      hasBorderColoring: true,
      draggable: true,
      selected: true,
      type: PageConfigurationTypes.TABLE,
    },
    {
      value: 'logbookStatesCountColumn',
      name: this.translate.instant('general.datatable.headers.states'),
      sortable: false,
      actionColumn: true,
      draggable: true,
      selected: true,
      type: PageConfigurationTypes.TABLE,
    },
    {
      value: 'stateDurationFormatted',
      name: this.translate.instant('general.datatable.headers.stateDuration'),
      sortable: false,
      draggable: true,
      selected: false,
      type: PageConfigurationTypes.TABLE,
    },
    {
      value: 'actionColumn',
      name: this.translate.instant('general.datatable.headers.actions'),
      sortable: false,
      actionColumn: true,
      draggable: true,
      selected: true,
      type: PageConfigurationTypes.TABLE,
    },
  ];
  public formTableHeaders: ITableHeader[] = [
    {
      value: 'name',
      name: this.translate.instant('general.datatable.headers.form'),
      selected: true,
      draggable: true,
      disabled: true,
      type: PageConfigurationTypes.TABLE,
      pointerEvents: 'all',
      sort: {
        type: 'none',
        sortIconClass: 'none-type',
      },
    },
    {
      value: 'formIdFormatted',
      name: this.translate.instant('general.datatable.headers.formId'),
      selected: true,
      draggable: true,
      type: PageConfigurationTypes.TABLE,
      pointerEvents: 'all',
      sort: {
        type: 'none',
        sortIconClass: 'none-type',
      },
    },
    {
      value: 'legacyId',
      name: this.translate.instant('general.datatable.headers.formLegacyId'),
      selected: true,
      draggable: true,
      type: PageConfigurationTypes.TABLE,
      pointerEvents: 'all',
      sort: {
        type: 'none',
        sortIconClass: 'none-type',
      },
    },
    {
      value: 'versionNumber',
      name: this.translate.instant('general.datatable.headers.versionNumber'),
      selected: true,
      draggable: true,
      type: PageConfigurationTypes.TABLE,
      pointerEvents: 'all',
      sort: {
        type: 'none',
        sortIconClass: 'none-type',
      },
    },
    {
      value: 'createdBy',
      name: this.translate.instant('general.datatable.headers.createdBy'),
      selected: true,
      draggable: true,
      sortable: false,
      type: PageConfigurationTypes.TABLE,
      pointerEvents: 'all',
      sort: {
        type: 'none',
        sortIconClass: 'none-type',
      },
    },
    {
      value: 'issuedDateFormatted',
      name: this.translate.instant('general.datatable.headers.issuedDate'),
      selected: true,
      draggable: true,
      type: PageConfigurationTypes.TABLE,
      pointerEvents: 'all',
      sort: {
        type: 'none',
        sortIconClass: 'none-type',
      },
    },
    {
      value: 'activatedAtFormatted',
      name: this.translate.instant('general.datatable.headers.activationDate'),
      selected: true,
      draggable: true,
      type: PageConfigurationTypes.TABLE,
      pointerEvents: 'all',
      sort: {
        type: 'none',
        sortIconClass: 'none-type',
      },
    },
    {
      value: 'activityTypeFormatted',
      name: this.translate.instant('general.datatable.headers.activityType'),
      selected: true,
      draggable: true,
      sortable: false,
      type: PageConfigurationTypes.TABLE,
      pointerEvents: 'all',
      sort: {
        type: 'none',
        sortIconClass: 'none-type',
      },
    },
    {
      value: 'approvalStepPosition',
      name: this.translate.instant('general.datatable.headers.status'),
      selected: true,
      draggable: true,
      sortable: false,
      type: PageConfigurationTypes.TABLE,
      pointerEvents: 'all',
      sort: {
        type: 'none',
        sortIconClass: 'none-type',
      },
    },
    {
      value: 'allVersion',
      name: this.translate.instant('general.datatable.headers.actions'),
      selected: true,
      sortable: false,
      draggable: true,
      type: PageConfigurationTypes.TABLE,
      pointerEvents: 'all',
      sort: {
        type: 'none',
        sortIconClass: 'none-type',
      },
    },
  ];
  public allLogbookVersionsTableHeaders: ITableHeader[] = [
    {
      value: 'name',
      name: this.translate.instant('general.datatable.headers.logbook'),
      selected: true,
      draggable: true,
      disabled: true,
      type: PageConfigurationTypes.TABLE,
      pointerEvents: 'all',
      sort: {
        type: 'none',
        sortIconClass: 'none-type',
      },
    },
    {
      value: 'logbookId',
      name: this.translate.instant('general.datatable.headers.logbookId'),
      selected: true,
      draggable: true,
      type: PageConfigurationTypes.TABLE,
      pointerEvents: 'all',
      sort: {
        type: 'none',
        sortIconClass: 'none-type',
      },
    },
    {
      value: 'legacyId',
      name: this.translate.instant('general.datatable.headers.logbookLegacyId'),
      selected: true,
      draggable: true,
      type: PageConfigurationTypes.TABLE,
      pointerEvents: 'all',
      sort: {
        type: 'none',
        sortIconClass: 'none-type',
      },
    },
    {
      value: 'versionNumber',
      name: this.translate.instant('general.datatable.headers.versionNumber'),
      selected: true,
      draggable: true,
      type: PageConfigurationTypes.TABLE,
      pointerEvents: 'all',
      sort: {
        type: 'none',
        sortIconClass: 'none-type',
      },
    },
    {
      value: 'forms',
      name: this.translate.instant('general.datatable.headers.forms'),
      selected: true,
      draggable: true,
      sortable: false,
      type: PageConfigurationTypes.TABLE,
      pointerEvents: 'all',
      sort: {
        type: 'none',
        sortIconClass: 'none-type',
      },
    },
    {
      value: 'createdBy',
      name: this.translate.instant('general.datatable.headers.createdBy'),
      selected: true,
      draggable: true,
      sortable: false,
      type: PageConfigurationTypes.TABLE,
      pointerEvents: 'all',
      sort: {
        type: 'none',
        sortIconClass: 'none-type',
      },
    },
    {
      value: 'issuedDateFormatted',
      name: this.translate.instant('general.datatable.headers.issuedDate'),
      selected: true,
      draggable: true,
      type: PageConfigurationTypes.TABLE,
      pointerEvents: 'all',
      sort: {
        type: 'none',
        sortIconClass: 'none-type',
      },
    },
    {
      value: 'activatedAtFormatted',
      name: this.translate.instant('general.datatable.headers.activationDate'),
      selected: true,
      draggable: true,
      type: PageConfigurationTypes.TABLE,
      pointerEvents: 'all',
      sort: {
        type: 'none',
        sortIconClass: 'none-type',
      },
    },
    {
      value: 'approvalStepPosition',
      name: this.translate.instant('general.datatable.headers.status'),
      selected: true,
      draggable: true,
      sortable: false,
      type: PageConfigurationTypes.TABLE,
      pointerEvents: 'all',
      sort: {
        type: 'none',
        sortIconClass: 'none-type',
      },
    },
    {
      value: 'allVersion',
      name: this.translate.instant('general.datatable.headers.actions'),
      selected: true,
      draggable: true,
      sortable: false,
      type: PageConfigurationTypes.TABLE,
      pointerEvents: 'all',
      sort: {
        type: 'none',
        sortIconClass: 'none-type',
      },
    },
    {
      value: 'stateDurationFormatted',
      name: this.translate.instant('general.datatable.headers.stateDuration'),
      draggable: true,
      selected: false,
      type: PageConfigurationTypes.TABLE,
      pointerEvents: 'all',
      sort: {
        type: 'none',
        sortIconClass: 'none-type',
      },
    },
  ];

  public userTableHeaderDefaults: ITableHeader[] = UsersService.getUserTableHeaderDefaults(this.translate);
  public homeLogsHistoryTableHeaderDefaults: ITableHeader[] = HomeService.getFormSubmissionTableHeaderDefaults(
    this.translate,
  );
  public logFormEntriesHeaderDefaults: ITableHeader[] = LogsFormEntriesService.getLogsFormEntriesTableHeaderDefaults(
    this.translate,
  );
  public logbookTasksHeaderDefaults: ITableHeader[] = LogbookTasksService.getLogbookTasksTableHeaderDefaults(
    this.translate,
  );
  public formTasksHeaderDefaults: ITableHeader[] = FormTasksService.getFormTasksTableHeaderDefaults(this.translate);
  public masterDataTasksHeaderDefaults: ITableHeader[] = MasterDataTasksService.getMasterDataTasksTableHeaderDefaults(
    this.translate,
  );
  public formEntriesHeaderDefaults: ITableHeader[] = FormEntriesService.getFormEntriesTableHeaderDefaults(
    this.translate,
  );
  public readonly logsReportsTableHeaderDefaults: ITableHeader[] = LogsReportsService.getLogsReportsTableHeaderDefaults(
    this.translate,
  );
  private disabledSubscription: Subscription | undefined;
  public isConfigurationButtonDisabled: boolean = HelperService.disablePageConfigurationSubject.getValue();
  public searchBoxText!: string | null;
  public configurationFormData: IFormPageConfiguration = {
    forms: {
      isEnabled: false,
      value: [],
      rules: [],
    },
  };
  private formEntriesColumns: ILogsFormEntriesColumn[] = [];
  private isUserHomeConfigurationModal: boolean = false;
  private isFirstClick: boolean = true;
  public readonly masterDataText: string = this.translate.instant('pageConfiguration.modal.labels.masterData');
  public readonly formFieldsText: string = this.translate.instant('pageConfiguration.modal.labels.formFields');
  private selectedLogbookId!: number;
  public popUpFormNames: string[] = [];
  public clickedButtonId: string | undefined;
  public dynamicColumns: ITableHeader[] = [];
  private formEntriesColumnLoaded$: boolean | undefined;
  public activeLogbookTab: string = '';
  private isPageConfigurationUpdate: boolean = false;
  private isAllLogbookVersionPageConfigurationUpdate: boolean = false;
  public userFormSettingsConfiguration!: IConfigurationMeta[];
  public userLogbookSettingsConfiguration!: IConfigurationMeta[];
  public userAllLogbookVersionSettingsConfiguration!: IConfigurationMeta[];

  public trackByValue(_index: number, item: ITableHeader) {
    return item.value;
  }

  constructor(
    private readonly configModal: NgbModal,
    private readonly store: Store<LogbookAppState>,
    private readonly translate: TranslateService,
    public readonly helperService: HelperService,
    private readonly actionsSubject: ActionsSubject,
    private readonly saveChangesModal: NgbModal,
    public readonly formEntriesService: FormEntriesService,
    public route: ActivatedRoute,
    private readonly storeActions: ActionsSubject,
  ) {}

  public ngOnInit(): void {
    window.addEventListener('scroll', this.onScrollEvent, true);
    this.disabledSubscription = HelperService.disablePageConfigurationSubject.subscribe(
      (isDisabled: boolean) => (this.isConfigurationButtonDisabled = isDisabled),
    );
    this.storeSubscriptions.push(
      this.store.select('user').subscribe((state: User) => {
        if (state.isConfigurationLoaded && !state.isConfigurationLoading) {
          this.userConfigurationData = _.cloneDeep(state.configuration?.pageConfig) ?? null;
          this.userId$ = Number(state.userId);
          this.clientId$ = Number(state.clientId);
        }
      }),
      this.store.select('pageHeaderStore').subscribe((state: IPageHeaderState) => {
        if (state.pageHeader?.title === 'logbook') {
          this.activeLogbookTab = ELogbookAndLogbookVersionTabs.LOGBOOK;
        } else if (state.pageHeader?.title === 'allLogbookVersion') {
          this.activeLogbookTab = ELogbookAndLogbookVersionTabs.ALL_LOGBOOK_VERSION;
        }
      }),
    );
    this.getTabs(this.pageHeader.titleKey ?? '', this.userConfigurationData);
    this.storeActions.pipe(ofType(FormEntriesActions.FORM_ENTRIES_COLUMN_LOADED)).subscribe((response: any) => {
      if (!_.isNaN(this.selectedLogbookId)) {
        this.formEntriesColumns = response.payload.data;
        const params: IPageConfigurationParams = {
          userId: this.userId$,
          logbookId: this.selectedLogbookId,
        };

        this.isUserHomeConfigurationModal =
          this.currentTabComponent === 'Home Logs History' || this.currentTabComponent === 'LogbookHomeDetailComponent';

        if (this.isUserHomeConfigurationModal && !this.formEntriesColumnLoaded$) {
          this.store.dispatch(new PageConfigurationActions.UserHomePageConfigurationLoading(params));
          this.formEntriesColumnLoaded$ = true;
          this.formDropdownData = [];
          this.configurationFormData = {
            forms: {
              isEnabled: false,
              value: [],
              rules: [],
            },
          };
        }
      }
    });
    this.storeActions
      .pipe(ofType(PageConfigurationActions.USER_HOME_PAGE_CONFIGURATION_LOADED))
      .subscribe((response: any) => {
        this.logbookHomeConfiguration = _.get(response.payload.data, 'configuration.meta', null);
        this.isClientConfiguration = _.get(response.payload.data, 'isClientConfiguration', undefined);
        this.generateDynamicDefaultColumns();
      });
    this.storeActions.pipe(ofType(LogbookActions.SET_ALL_LOGBOOK_VERSION_TABLE_SETTINGS)).subscribe((response: any) => {
      this.allLogbookVersionTabs = response.payload;
    });
    this.storeActions
      .pipe(ofType(PageConfigurationActions.USER_FORM_SETTINGS_PAGE_CONFIGURATION_LOADED))
      .subscribe((response: any) => {
        if (response.payload.data.length) {
          this.isPageConfigurationUpdate = true;
          this.userFormSettingsConfiguration = _.get(response.payload.data, '[0].meta', null);
        } else {
          this.isPageConfigurationUpdate = false;
        }

        this.initializeTableSettings(
          this.formTableHeaders,
          ComponentNamesForUserConfiguration.Forms,
          this.userFormSettingsConfiguration,
          EPageConfigurationLocation.FORM_SETTINGS,
        );
      });
    this.storeActions
      .pipe(ofType(PageConfigurationActions.USER_LOGBOOK_SETTINGS_PAGE_CONFIGURATION_LOADED))
      .subscribe((response: any) => {
        this.logbookTabs = [];

        if (response.payload.data.length) {
          this.isPageConfigurationUpdate = true;
          this.userLogbookSettingsConfiguration = _.get(response.payload.data, '[0].meta', null);
        } else {
          this.isPageConfigurationUpdate = false;
        }

        this.initializeTableSettings(
          this.logbookTableHeaders,
          ComponentNamesForUserConfiguration.LogbooksTab,
          this.userLogbookSettingsConfiguration,
          EPageConfigurationLocation.LOGBOOK_SETTINGS,
        );
      });
    this.storeActions
      .pipe(ofType(PageConfigurationActions.USER_ALL_LOGBOOK_VERSION_SETTINGS_PAGE_CONFIGURATION_LOADED))
      .subscribe((response: any) => {
        this.allLogbookVersionTabs = [];

        if (response.payload.data.length) {
          this.isAllLogbookVersionPageConfigurationUpdate = true;
          this.userAllLogbookVersionSettingsConfiguration = _.get(response.payload.data, '[0].meta', null);
        } else {
          this.isAllLogbookVersionPageConfigurationUpdate = false;
        }

        this.initializeTableSettings(
          this.allLogbookVersionsTableHeaders,
          ComponentNamesForUserConfiguration.AllLogbookVersionsTab,
          this.userAllLogbookVersionSettingsConfiguration,
          EPageConfigurationLocation.ALL_LOGBOOK_VERSION_SETTINGS,
        );
      });
    this.storeActions.pipe(ofType(PageConfigurationActions.FETCH_ERROR)).subscribe(() => {
      this.formEntriesColumnLoaded$ = false;
    });
  }

  private initializeTableSettings(
    tableHeader: ITableHeader[],
    component: ComponentNamesForUserConfiguration,
    configuration: IConfigurationMeta[],
    location: EPageConfigurationLocation,
  ): void {
    this.iterateOverDefault(tableHeader, null, component, configuration);
    this.tabs[0].table = _.cloneDeep(this.tabsToBeAtTheEnd);
    this.tabs[0].table = PageConfigurationComponent.sortTableColumns(this.tabs[0].table);
    this.generateUserDefaultConfiguration(location);
    this.initialTabs = _.cloneDeep(this.tabs);
    this.apply(false);
  }

  public getFieldsForm(item: any, buttonId?: string): void {
    this.popUpFormNames = item.formsWithNames;
    this.clickedButtonId = buttonId;
  }

  private setHomeStore(): void {
    this.store
      .select('homeStore')
      .subscribe((state: IHomeState) => {
        this.selectedLogbookId = Number(window.location.hash.substring(1).split('/')[2]);

        if (!_.isNaN(this.selectedLogbookId) && (state.networkLogbooks.length > 0 || state.logbookHomeDetail)) {
          const formIds: number[] =
            state.networkLogbooks?.find((item: IHomeLogbook): boolean => item.id === this.selectedLogbookId)
              ?.selectedForms ??
            state.logbookHomeDetail?.scopedForms.map((scopedForm: IFormVersion) => {
              return scopedForm.form.id;
            });

          if (!this.formEntriesColumnLoaded$) {
            this.store.dispatch(
              new FormEntriesActions.StartFormEntriesColumnLoading(formIds, false, this.selectedLogbookId),
            );
          }
        }
      })
      .unsubscribe();
  }

  private generateTableConfiguration(searchText?: string): void {
    this.generalConfigurationArray = [];
    this.createFieldsHeader(searchText);

    this.allFormFieldColumns.forEach(({ type, formVersionObjects }: { type: string; formVersionObjects: any[] }) => {
      this.generalConfigurationArray.push({
        type,
        groupItems: formVersionObjects.map(({ value, ...rest }) => ({
          ...rest,
          value,
          name:
            rest.formIoType === 'file'
              ? `${rest.name} (${this.translate.instant('pageConfiguration.modal.labels.file')})`
              : rest.name,
          selected: this.tabs[0].table.some((tableItem: ITableHeader): boolean => tableItem.value === value),
        })),
      });
    });

    this.checkButtonStatus();
  }

  private generateUserDefaultConfiguration(type: EPageConfigurationLocation): void {
    const configurationMeta: IConfigurationMeta[] = this.getConfigurationMeta(type);
    const tableHeader: ITableHeader[] = this.generateTableHeader(configurationMeta);
    this.tabs[0].table = _.cloneDeep(tableHeader);

    if (type === EPageConfigurationLocation.LOGBOOK_SETTINGS) {
      this.logbookTabs = _.cloneDeep(tableHeader);
    } else if (type === EPageConfigurationLocation.ALL_LOGBOOK_VERSION_SETTINGS) {
      this.allLogbookVersionTabs = _.cloneDeep(tableHeader);
    }
  }

  private getConfigurationMeta(type: EPageConfigurationLocation): IConfigurationMeta[] {
    switch (type) {
      case EPageConfigurationLocation.FORM_SETTINGS:
        return this.userFormSettingsConfiguration;
      case EPageConfigurationLocation.LOGBOOK_SETTINGS:
        return this.userLogbookSettingsConfiguration;
      case EPageConfigurationLocation.ALL_LOGBOOK_VERSION_SETTINGS:
        return this.userAllLogbookVersionSettingsConfiguration;
      default:
        return [];
    }
  }

  private generateTableHeader(configurationMeta: IConfigurationMeta[]): ITableHeader[] {
    if (!configurationMeta) {
      return this.tabs[0].table;
    }

    const tableHeader: ITableHeader[] = [];
    let unMatchedIndexCount: number = 1;

    this.tabs[0].table.forEach((table: ITableHeader) => {
      const index: number = configurationMeta.findIndex(
        (config: IConfigurationMeta): boolean => config.value === table.value,
      );

      if (index === -1) {
        tableHeader[this.tabs[0].table.length - unMatchedIndexCount] = {
          ...table,
          selected: false,
        };
        unMatchedIndexCount += 1;
      } else {
        tableHeader[index] = table;
      }
    });
    return tableHeader;
  }

  private createFieldsHeader(searchText?: string): void {
    this.selectedRecords = _.map(
      this.tabs[0].table.filter((column: ITableHeader) => column.selected || column.disabled),
      'value',
    );

    const logbookFormFields: ITableHeader[] = this.homeLogsHistoryTableHeaderDefaults.filter(
      (item: ITableHeader): boolean => item.formVersionDbId !== undefined,
    );

    if (this.selectedForms) {
      this.formFieldsHeader = logbookFormFields.filter((item: ITableHeader) => {
        return item.formIds?.some((formId: string) =>
          this.selectedForms?.some((secondItem: IConfigurationDropdown): boolean => secondItem.id === formId),
        );
      });
    } else {
      this.formFieldsHeader = logbookFormFields;
    }

    this.masterDataHeader = this.homeLogsHistoryTableHeaderDefaults.filter(
      (item: ITableHeader): boolean => item.formVersionDbId === undefined,
    );

    if (searchText) {
      this.masterDataHeader = this.masterDataHeader.filter((master: ITableHeader) =>
        master.name.toLowerCase().includes(searchText.toLowerCase()),
      );
      this.formFieldsHeader = this.formFieldsHeader.filter((form: ITableHeader) =>
        form.name.toLowerCase().includes(searchText.toLowerCase()),
      );
    }

    this.headerWithFormFields = {
      [this.masterDataText]: this.masterDataHeader,
      ...(this.formFieldsHeader.length === 0
        ? { [this.formFieldsText]: this.formFieldsHeader }
        : _.groupBy(this.formFieldsHeader, 'type')),
    };
    this.allFormFieldColumns = Object.entries(this.headerWithFormFields).map(([key, value]) => ({
      type: key,
      formVersionObjects: value,
    }));
  }

  private checkButtonStatus(): void {
    this.isResetButtonDisabled =
      this.tabs[0].table.length === this.tabs[0].table.filter((table: ITableHeader) => table.disabled).length;
    this.isSelectAllButtonDisabled = this.generalConfigurationArray.every((item: ITableHeaderGroup): boolean =>
      item.groupItems.every((groupItem: ITableHeader): boolean => groupItem.selected === true),
    );
  }

  private generateDynamicDefaultColumns(): void {
    this.dynamicColumns = [];
    this.formEntriesColumns.forEach((column: ILogsFormEntriesColumn) => {
      const chosenDynamicColumnKey: string =
        column.selectedType === 'file'
          ? this.formEntriesService.fileDynamicColumnPrefix + column.selectedLabel.toLowerCase().replace(/\s/g, '')
          : this.formEntriesService.dynamicColumnPrefix + column.selectedLabel.toLowerCase().replace(/\s/g, '');

      const matchedColumn: ITableHeader | undefined = this.dynamicColumns.find(
        (item: ITableHeader): boolean => item.value === chosenDynamicColumnKey,
      );

      if (!this.formDropdownData.some((form: IConfigurationDropdown): boolean => form.id === column.formId)) {
        this.formDropdownData.push({
          id: String(column.formId),
          name: `${column.formId} - ${column.formName}`,
        });
        this.configurationFormData.forms.value?.push({
          id: String(column.formId),
          name: `${column.formId} - ${column.formName}`,
        });
      }

      if (!matchedColumn) {
        const newColumn: any = {
          value: chosenDynamicColumnKey,
          name: column.selectedLabel,
          sortable: false,
          selected: false,
          draggable: true,
          formIoType: column.selectedType,
          selectedInnerLabels: column.selectedInnerLabels,
          type: this.formFieldsText,
          formVersionDbId: column.formVersionDbId,
          formName: column.formName,
          versionNumber: column.versionNumber,
          formIds: [column.formId],
          formNames: [column.formName],
          formsWithNames: [`${column.formId} - ${column.formName} v.${column.versionNumber}`],
        };
        this.dynamicColumns.push(newColumn);
      } else {
        const matchedColumn: ITableHeader | undefined = this.dynamicColumns.find(
          (item: ITableHeader): boolean => item.value === chosenDynamicColumnKey,
        );

        if (!matchedColumn?.formIds?.includes(String(column.formId))) {
          matchedColumn?.formIds?.push(String(column.formId));
          matchedColumn?.formsWithNames?.push(`${column.formId} - ${column.formName} v.${column.versionNumber}`);
          matchedColumn?.formNames?.push(String(column.formName));
        }
      }
    });

    this.homeLogsHistoryTableHeaderDefaults = HomeService.getFormSubmissionTableHeaderDefaults(this.translate).concat(
      this.dynamicColumns,
    );

    this.iterateOverDefault(
      this.homeLogsHistoryTableHeaderDefaults,
      this.userConfigurationData,
      ComponentNamesForUserConfiguration.LogbookHomeDetail,
      this.logbookHomeConfiguration,
    );
    this.generateTableConfiguration();
    this.initialTabs = _.cloneDeep(this.tabs);
    this.store.dispatch(new HomeActions.SetTableSettings(_.cloneDeep(this.tabs[0].table), false));
  }

  public drop($event: CdkDragDrop<string[]>, data: any): void {
    const hasNotDraggableColumn: boolean = data[0].draggable === false;
    const previousIndex: number = hasNotDraggableColumn ? $event.previousIndex + 1 : $event.previousIndex;
    const currentIndex: number = hasNotDraggableColumn ? $event.currentIndex + 1 : $event.currentIndex;

    this.changesMade = true;
    moveItemInArray(data, previousIndex, currentIndex);
  }

  public setRemoteColumns() {
    switch (this.currentTabComponent) {
      case 'reportsFormEntries':
        this.tabsToBeAtTheEnd = [];
        this.tabs[0].table = [];

        this.store
          .select('formEntriesStore')
          .subscribe((state) => {
            if (state.tableHeaders !== []) {
              this.formEntriesHeaderDefaults = state.tableDefaultHeaders;
              this.iterateOverDefault(
                state.tableDefaultHeaders,
                { FormEntriesComponent: state.tableHeaders },
                ComponentNamesForUserConfiguration.FormEntries,
              );
              this.tabs[0].table = _.cloneDeep(this.tabs[0].table.concat(this.tabsToBeAtTheEnd));
              this.tabs[0].table = PageConfigurationComponent.sortTableColumns(this.tabs[0].table);

              this.selectedRecords = _.map(
                this.tabs[0].table.filter((column: ITableHeader) => column.selected || column.disabled),
                'value',
              );
            }
          })
          .unsubscribe();
        break;
      case 'Home Logs History':
        if (this.isFirstClick) {
          this.setHomeStore();
        }
        break;
    }
  }

  public showModal(newTemplateRef: TemplateRef<any>, templateRef: TemplateRef<any>, remoteColumnFetch = false): void {
    if (this.currentTabComponent === ELogbookAndLogbookVersionTabs.LOGBOOK) {
      this.tabs[0].table = _.cloneDeep(
        this.activeLogbookTab === ELogbookAndLogbookVersionTabs.LOGBOOK
          ? this.logbookTabs
          : this.activeLogbookTab === ELogbookAndLogbookVersionTabs.ALL_LOGBOOK_VERSION
          ? this.allLogbookVersionTabs
          : this.tabs[0].table,
      );
      this.selectedRecords = _.map(
        this.tabs[0].table.filter((column: ITableHeader) => column.selected || column.disabled),
        'value',
      );
    }

    if (remoteColumnFetch) {
      this.setRemoteColumns();
    }

    this.configModalRef = this.configModal.open(this.isUserHomeConfigurationModal ? newTemplateRef : templateRef, {
      keyboard: false,
      backdrop: 'static',
      windowClass: this.isUserHomeConfigurationModal ? this.scwModalXl : this.scwModalSm,
    });
    this.modalArray.push(this.configModalRef);

    if (this.isUserHomeConfigurationModal) {
      this.isFirstClick = false;
      this.searchBoxText = '';
      this.generateTableConfiguration();

      if (this.selectedForms?.length === 0) {
        this.configurationFormData = {
          forms: {
            isEnabled: false,
            value: [],
            rules: [],
          },
        };
      }
    }
  }

  public onFormChange(event: any) {
    if (this.configurationFormData.forms.value !== null) {
      this.selectedForms = event;
      this.generateTableConfiguration();
    }
  }

  public getTabs(pageHeaderKey: string, userConfigurationData: IUserConfiguration | null): void {
    this.resetParameters();
    this.forceRemoteColumnLoad = false;
    this.showReturnToMyReportButton = false;
    switch (pageHeaderKey) {
      case 'Users':
        let isNotificationScopeExist: boolean = true;
        this.store.select('clientStore').subscribe((state: IClientSettingsState) => {
          isNotificationScopeExist = !!state.clientCommonInformation?.notificationScope
            ?.split(',')
            ?.filter((item: string) => item !== '3' && item !== '').length;
          if (!isNotificationScopeExist) {
            this.userTableHeaderDefaults = this.userTableHeaderDefaults.filter(
              (item: ITableHeader) => item.value !== 'notificationScopeFormatted',
            );
          }
        });
        this.iterateOverDefault(
          this.userTableHeaderDefaults,
          userConfigurationData,
          ComponentNamesForUserConfiguration.UserSettings,
        );
        this.currentTabComponent = pageHeaderKey;
        break;
      case 'My Tasks':
        this.route.fragment.subscribe((fragmentStr) => {
          switch (fragmentStr) {
            case null:
            case 'logs-form-entries':
              this.iterateOverDefault(
                this.logFormEntriesHeaderDefaults,
                userConfigurationData,
                ComponentNamesForUserConfiguration.LogsFormEntries,
              );
              this.currentTabComponent = pageHeaderKey;
              break;
            case 'logbook-tasks':
              this.iterateOverDefault(
                this.logbookTasksHeaderDefaults,
                userConfigurationData,
                ComponentNamesForUserConfiguration.LogbookTasks,
              );
              this.currentTabComponent = pageHeaderKey;
              break;
            case 'form-tasks':
              this.iterateOverDefault(
                this.formTasksHeaderDefaults,
                userConfigurationData,
                ComponentNamesForUserConfiguration.FormTasks,
              );
              this.currentTabComponent = pageHeaderKey;
              break;

            case 'master-data':
              this.iterateOverDefault(
                this.masterDataTasksHeaderDefaults,
                userConfigurationData,
                ComponentNamesForUserConfiguration.MasterDataTasks,
              );
              this.currentTabComponent = pageHeaderKey;
              break;
          }
        });
        break;
      case 'Home Logs History':
        this.setHomeStore();
        this.currentTabComponent = pageHeaderKey;
        this.forceRemoteColumnLoad = true;
        break;
      case 'reportsFormEntries':
        this.route.queryParams.subscribe((item) => {
          this.iterateOverDefault(
            this.formEntriesHeaderDefaults,
            userConfigurationData,
            ComponentNamesForUserConfiguration.FormEntries,
          );
          this.currentTabComponent = pageHeaderKey;
          this.forceRemoteColumnLoad = true;
          if (item && item['reportId']) {
            this.showReturnToMyReportButton = true;
          }
        });
        break;
      case 'logsReports':
        this.iterateOverDefault(
          this.logsReportsTableHeaderDefaults,
          userConfigurationData,
          ComponentNamesForUserConfiguration.LogsReports,
        );
        this.currentTabComponent = pageHeaderKey;
        break;
      case 'Form Template Reports':
        this.iterateOverDefault(
          this.formTemplatesHeaders,
          userConfigurationData,
          ComponentNamesForUserConfiguration.FormTemplates,
        );
        this.currentTabComponent = pageHeaderKey;
        break;
      case 'Logbook Template Reports':
        this.iterateOverDefault(
          this.logbookTemplatesHeaders,
          userConfigurationData,
          ComponentNamesForUserConfiguration.LogbookTemplates,
        );
        this.currentTabComponent = pageHeaderKey;
        break;
      case 'Logbook':
      case 'logbookSettings':
      case 'All Logbook Versions':
        this.currentTabComponent = this.activeLogbookTab;
        return;
      case 'forms':
        this.currentTabComponent = pageHeaderKey;
        return;
    }

    this.tabs[0].table = _.cloneDeep(this.tabs[0].table.concat(this.tabsToBeAtTheEnd));
    this.tabs[0].table = PageConfigurationComponent.sortTableColumns(this.tabs[0].table);

    this.initialTabs = _.cloneDeep(this.tabs);
    this.apply(false);
  }

  private arrangeHeaders(
    defaultHeaders: any[],
    userConfigurationData: any,
    component: string,
    checkedItemIndex: number,
  ): number {
    const componentDataFromUserConfig = _.get(userConfigurationData, component);
    let result = checkedItemIndex;

    for (const tab of defaultHeaders) {
      if (componentDataFromUserConfig) {
        result = componentDataFromUserConfig[0].findIndex((item: number) => item === tab.value);
      }

      if (result !== -1 && !tab?.isTableRow) {
        this.tabs[0].table[result] = tab;
        continue;
      }

      if (result !== -1 && tab?.isTableRow) {
        this.rows.push(tab);
        continue;
      }

      if (checkedItemIndex === -1 && tab.value) {
        this.tabsToBeAtTheEnd.push(tab);
      }
    }

    return result;
  }

  public apply(setAsDefault: boolean): void {
    this.changesMade = false;
    this.configModalRef?.close();
    this.initialTabs = _.cloneDeep(this.tabs);

    this.closeSaveModal();

    switch (this.pageHeader.titleKey) {
      case 'Users':
        this.setUserTableSettings(setAsDefault);
        break;
      case 'My Tasks':
        this.setMyTasksTableSettings(setAsDefault);
        break;
      case 'Home Logs History':
        this.setHomeLogsTableSettings(setAsDefault);
        break;
      case 'reportsFormEntries':
        this.setReportsFromEntriesTableSettings(setAsDefault);
        break;
      case 'logsReports':
        this.setLogsReportTableSettings(setAsDefault);
        break;
      case 'Form Template Reports':
        this.setFormTemplateReportsTableSettings(setAsDefault);
        break;
      case 'Logbook Template Reports':
        this.setLogbookTemplateReportsTableSettings(setAsDefault);
        break;
      case 'logbookSettings':
      case 'Logbook':
      case 'All Logbook Versions':
        if (this.activeLogbookTab === ELogbookAndLogbookVersionTabs.LOGBOOK) {
          this.setLogbookTableSettings(setAsDefault);
        } else if (this.activeLogbookTab === ELogbookAndLogbookVersionTabs.ALL_LOGBOOK_VERSION) {
          this.setAllLogbookVersionTableSettings(setAsDefault);
        }
        break;
      case 'forms':
        this.setFormTableSettings(setAsDefault);
        break;
    }

    this.selectedRecords = _.map(
      this.tabs[0].table.filter((column: ITableHeader) => column.selected || column.disabled),
      'value',
    );
  }

  private setUserTableSettings(setAsDefault: boolean): void {
    this.store.dispatch(
      new UserSettingsActions.SetTableSettings(
        _.cloneDeep(this.tabs[0].table).filter((item: ITableHeader) => item.selected),
        setAsDefault,
      ),
    );

    this.applySetAsDefault(
      setAsDefault,
      this.userTableHeaderDefaults,
      true,
      ComponentNamesForUserConfiguration.UserSettings,
      'userSettingsStore',
    );
  }

  private setMyTasksTableSettings(setAsDefault: boolean): void {
    this.route.fragment.subscribe((fragmentStr) => {
      switch (fragmentStr) {
        case null:
        case 'logs-form-entries':
          this.store.dispatch(
            new LogsFormEntriesActions.SetTableSettings(
              _.cloneDeep(this.tabs[0].table).filter((item: ITableHeader) => item.selected),
              setAsDefault,
            ),
          );

          this.applySetAsDefault(
            setAsDefault,
            this.logFormEntriesHeaderDefaults,
            true,
            ComponentNamesForUserConfiguration.LogsFormEntries,
            'logsFormEntriesStore',
          );
          break;
        case 'logbook-tasks':
          this.store.dispatch(
            new LogbookTasksActions.SetTableSettings(
              _.cloneDeep(this.tabs[0].table).filter((item: ITableHeader) => item.selected),
              setAsDefault,
            ),
          );

          this.applySetAsDefault(
            setAsDefault,
            this.logbookTasksHeaderDefaults,
            true,
            ComponentNamesForUserConfiguration.LogbookTasks,
            'logbookTasksStore',
          );
          break;
        case 'form-tasks':
          this.store.dispatch(
            new FormTasksActions.SetTableSettings(
              _.cloneDeep(this.tabs[0].table).filter((item: ITableHeader) => item.selected),
              setAsDefault,
            ),
          );

          this.applySetAsDefault(
            setAsDefault,
            this.formTasksHeaderDefaults,
            true,
            ComponentNamesForUserConfiguration.FormTasks,
            'formTasksStore',
          );
          break;
        case 'master-data':
          this.store.dispatch(
            new MasterDataTasksActions.SetTableSettings(
              _.cloneDeep(this.tabs[0].table).filter((item: ITableHeader) => item.selected),
              setAsDefault,
            ),
          );

          this.applySetAsDefault(
            setAsDefault,
            this.masterDataTasksHeaderDefaults,
            true,
            ComponentNamesForUserConfiguration.MasterDataTasks,
            'masterDataTasksStore',
          );
          break;
      }
    });
  }

  private setHomeLogsTableSettings(setAsDefault: boolean): void {
    this.store.dispatch(
      new HomeActions.SetTableSettings(
        _.cloneDeep(this.tabs[0].table).filter((item: ITableHeader) => item.selected),
        setAsDefault,
      ),
    );

    if (setAsDefault) {
      const meta: IConfigurationMeta[] = [];
      this.tabs[0].table.forEach((item: ITableHeader) => {
        meta.push({
          type:
            item.type === this.masterDataText
              ? EPageConfigurationFieldType.MASTER_DATA
              : EPageConfigurationFieldType.FORM_IO_FIELDS,
          formIds: item.formIds,
          value: item.value,
        });
      });

      const requestParams: IAddPageConfiguration = {
        payload: {
          meta,
          logbookIds: [Number(this.selectedLogbookId)],
          level: EPageConfigurationLevel.USER,
          location: EPageConfigurationLocation.HOME,
          createdById: this.userId$,
          clientId: this.clientId$,
        },
      };

      if (this.isClientConfiguration) {
        this.store.dispatch(new PageConfigurationActions.AddUserHomePageConfiguration(requestParams));
      } else {
        this.store.dispatch(new PageConfigurationActions.UpdateUserHomePageConfiguration(requestParams));
      }

      this.isClientConfiguration = false;
    }
  }

  private setReportsFromEntriesTableSettings(setAsDefault: boolean): void {
    this.store.dispatch(
      new FormEntriesActions.SetTableSettings(
        _.cloneDeep(this.tabs[0].table).filter((item: ITableHeader) => item.selected),
        setAsDefault,
      ),
    );

    if (setAsDefault) {
      if (this.showReturnToMyReportButton) {
        this.store.dispatch(
          new FormEntriesActions.InitializeSaveReportTableSettings(
            _.cloneDeep(this.tabs[0].table).filter((item: ITableHeader) => item.selected),
          ),
        );
      } else {
        this.applySetAsDefault(
          setAsDefault,
          this.formEntriesHeaderDefaults,
          true,
          ComponentNamesForUserConfiguration.FormEntries,
          'formEntriesStore',
        );
      }
    }
  }

  private setLogsReportTableSettings(setAsDefault: boolean): void {
    this.store.dispatch(
      new LogsReportsActions.SetTableSettings(
        _.cloneDeep(this.tabs[0].table).filter((item: ITableHeader) => item.selected),
        setAsDefault,
      ),
    );

    this.applySetAsDefault(
      setAsDefault,
      this.logsReportsTableHeaderDefaults,
      true,
      ComponentNamesForUserConfiguration.LogsReports,
      'logsReportsStore',
    );
  }

  private setFormTemplateReportsTableSettings(setAsDefault: boolean): void {
    this.store.dispatch(
      new FormTemplateActions.SetTableSettings(
        _.cloneDeep(this.tabs[0].table).filter((item: ITableHeader) => item.selected),
        setAsDefault,
      ),
    );

    this.applySetAsDefault(
      setAsDefault,
      this.formTemplatesHeaders,
      false,
      ComponentNamesForUserConfiguration.FormTemplates,
      'formTemplatesStore',
    );
  }

  private setLogbookTemplateReportsTableSettings(setAsDefault: boolean): void {
    this.store.dispatch(
      new LogbookTemplateActions.SetTableSettings(
        _.cloneDeep(this.tabs[0].table).filter((item: ITableHeader) => item.selected),
        setAsDefault,
      ),
    );

    if (setAsDefault) {
      this.helperService.onApplyClick(
        _.cloneDeep(this.tabs[0].table),
        this.logbookTemplatesHeaders,
        false,
        setAsDefault,
        ComponentNamesForUserConfiguration.LogbookTemplates,
        this.userId$,
        'logbookTemplatesStore',
      );
    }
  }

  private setLogbookTableSettings(setAsDefault: boolean): void {
    this.store.dispatch(
      new LogbookActions.SetTableSettings(
        _.cloneDeep(this.tabs[0].table).filter((item: ITableHeader) => item.selected),
        setAsDefault,
      ),
    );
    this.logbookTabs = _.cloneDeep(this.tabs[0].table);

    if (setAsDefault) {
      const requestParams: IAddPageConfiguration = this.prepareConfigurationRequestParameter(
        EPageConfigurationLocation.LOGBOOK_SETTINGS,
      );
      this.store.dispatch(
        new PageConfigurationActions.AddUserLogbookSettingsPageConfiguration(
          requestParams,
          this.isPageConfigurationUpdate,
        ),
      );
      this.isPageConfigurationUpdate = true;
    }
  }

  private setAllLogbookVersionTableSettings(setAsDefault: boolean): void {
    this.store.dispatch(
      new LogbookActions.SetAllLogbookVersionTableSettings(_.cloneDeep(this.tabs[0].table), setAsDefault),
    );

    if (setAsDefault) {
      const requestParams: IAddPageConfiguration = this.prepareConfigurationRequestParameter(
        EPageConfigurationLocation.ALL_LOGBOOK_VERSION_SETTINGS,
      );
      this.store.dispatch(
        new PageConfigurationActions.AddUserAllLogbookVersionSettingsPageConfiguration(
          requestParams,
          this.isAllLogbookVersionPageConfigurationUpdate,
        ),
      );
      this.isAllLogbookVersionPageConfigurationUpdate = true;
    }
  }

  private setFormTableSettings(setAsDefault: boolean): void {
    this.store.dispatch(
      new FormActions.SetTableSettings(
        _.cloneDeep(this.tabs[0].table).filter((item: ITableHeader) => item.selected),
        setAsDefault,
      ),
    );

    if (setAsDefault) {
      const requestParams: IAddPageConfiguration = this.prepareConfigurationRequestParameter(
        EPageConfigurationLocation.FORM_SETTINGS,
      );
      this.store.dispatch(
        new PageConfigurationActions.AddUserFormSettingsPageConfiguration(
          requestParams,
          this.isPageConfigurationUpdate,
        ),
      );
      this.isPageConfigurationUpdate = true;
    }
  }

  private prepareConfigurationRequestParameter(location: string): IAddPageConfiguration {
    const meta: IConfigurationMeta[] = [];
    this.tabs[0].table.forEach((item: ITableHeader) => {
      if (item.selected) {
        meta.push({
          type: EPageConfigurationFieldType.MASTER_DATA,
          value: item.value,
        });
      }
    });

    return {
      payload: {
        meta,
        location,
        level: EPageConfigurationLevel.USER,
        createdById: this.userId$,
        clientId: this.clientId$,
      },
    };
  }

  public dontApply(): void {
    this.changesMade = false;
    this.saveChangedModalRef.close();
    this.onCloseClick();
    this.tabs = _.cloneDeep(this.initialTabs);
    this.selectedRecords = _.map(
      this.tabs[0].table.filter((column: ITableHeader) => column.selected || column.disabled),
      'value',
    );
    this.searchBoxText = '';
    this.generateTableConfiguration();
  }

  public onSelectAllClick(): void {
    this.changesMade = true;
    this.generalConfigurationArray.forEach((item: ITableHeaderGroup) =>
      item.groupItems.forEach((groupItem: ITableHeader) => {
        if (!groupItem.selected) {
          groupItem.selected = true;
          this.tabs[0].table.push(groupItem);
        }
      }),
    );
    this.checkButtonStatus();
  }

  public onResetClick(): void {
    this.changesMade = true;
    this.generalConfigurationArray.forEach((item: ITableHeaderGroup) =>
      item.groupItems.forEach((groupItem: ITableHeader) => {
        if (groupItem.selected && !groupItem.disabled) {
          groupItem.selected = false;
        }
      }),
    );

    this.tabs[0].table = this.tabs[0].table.filter((table: ITableHeader) => table.disabled);
    this.checkButtonStatus();
  }

  public changeCheckBox(event: MatCheckboxChange, moveUncheckedToEnd: boolean): void {
    this.changesMade = true;
    PageConfigurationComponent.checkLinkedCheckbox(event.source.id.substring(22), this.tabs[0].table);

    if (moveUncheckedToEnd && !this.isSelectOrUnselectAllClicked) {
      this.tabs[0].table = PageConfigurationComponent.sortTableColumns(this.tabs[0].table);
    }

    const fieldName: string = event.source.id.substring('table-config-checkbox-'.length);

    if (this.isUserHomeConfigurationModal) {
      const selectedItem: ITableHeader = this.generalConfigurationArray
        .flatMap((group: ITableHeaderGroup) => group.groupItems)
        .find((groupItem: ITableHeader): boolean => groupItem.value === fieldName) as ITableHeader;

      if (event.checked) {
        selectedItem.selected = true;
        this.tabs[0].table.push(selectedItem);
        this.selectedRecords.push(fieldName);
      } else {
        if (selectedItem) {
          selectedItem.selected = false;
        }

        this.tabs[0].table = this.tabs[0].table.filter((item: ITableHeader): boolean => item.value !== fieldName);
        this.selectedRecords.splice(this.selectedRecords.indexOf(fieldName), 1);
      }

      this.checkButtonStatus();
    } else {
      if (event.checked) {
        this.selectedRecords.push(fieldName);
      } else {
        const index: number = this.selectedRecords.indexOf(fieldName);
        this.selectedRecords.splice(index, 1);
      }
    }
  }

  public static sortTableColumns(columnArray: any[]): any[] {
    const tableRows = columnArray.filter((c: any) => c.isTableRow || c.alwaysAtTheEnd);
    const tableColumns = columnArray
      .filter((c: any) => !c.isTableRow && !c.alwaysAtTheEnd)
      .sort((a, b) => {
        if (a.selected === b.selected) {
          return 0;
        }

        return a.selected ? -1 : 1;
      });

    return [...tableColumns, ...tableRows];
  }

  public onCloseClick(): void {
    if (this.changesMade) {
      this.saveChangedModalRef = this.saveChangesModal.open(this.unsavedChangesModalTemplateRef, {
        keyboard: false,
        backdrop: 'static',
        windowClass: this.scwModalSm,
      });
      this.modalArray.push(this.saveChangedModalRef);
    } else {
      this.configModalRef.close();
    }
  }

  public cdkDropListEnterPredicateColumn($event: any): boolean {
    return !Boolean($event?.isTableRow);
  }

  private iterateOverDefault(
    headerDefaults: ITableHeader[],
    userConfigurationData: IUserConfiguration | null,
    page: ComponentNamesForUserConfiguration,
    configurationHeaderDefault?: Partial<ITableHeader>[],
  ): void {
    this.isUserHomeConfigurationModal = page === 'LogbookHomeDetailComponent';

    if (!userConfigurationData?.[page] && !configurationHeaderDefault && !this.isUserHomeConfigurationModal) {
      this.tabsToBeAtTheEnd = [];
      switch (page) {
        case 'UserSettingsComponent':
          this.tabsToBeAtTheEnd = this.userTableHeaderDefaults.filter((item: ITableHeader) => item.value);
          break;
        case 'LogsFormEntriesComponent':
          this.tabsToBeAtTheEnd = this.logFormEntriesHeaderDefaults.filter((item: ITableHeader) => item.value);
          break;
        case 'LogbookTasksComponent':
          this.tabsToBeAtTheEnd = this.logbookTasksHeaderDefaults.filter((item: ITableHeader) => item.value);
          break;
        case 'FormTasksComponent':
          this.tabsToBeAtTheEnd = this.formTasksHeaderDefaults.filter((item: ITableHeader) => item.value);
          break;
        case 'MasterDataTasksComponent':
          this.tabsToBeAtTheEnd = this.masterDataTasksHeaderDefaults.filter((item: ITableHeader) => item.value);
          break;
        case 'LogbookHomeDetailComponent':
          this.tabsToBeAtTheEnd = this.homeLogsHistoryTableHeaderDefaults.filter(
            (item: ITableHeader) => item.value && item.selected,
          );
          this.currentTabComponent = page;
          this.tabs[0].table = this.tabsToBeAtTheEnd;
          break;
        case 'FormEntriesComponent':
          this.tabsToBeAtTheEnd = this.formEntriesHeaderDefaults.filter((item: ITableHeader) => item.value);
          this.currentTabComponent = page;
          break;
        case 'FormTemplatesComponent':
          this.tabsToBeAtTheEnd = this.formTemplatesHeaders.filter((item: ITableHeader) => item.value);
          break;
        case 'LogbookTemplatesComponent':
          this.tabsToBeAtTheEnd = this.logbookTemplatesHeaders.filter((item: ITableHeader) => item.value);
          break;
        case 'LogsReportsComponent':
          this.tabsToBeAtTheEnd = this.logsReportsTableHeaderDefaults.filter((item: ITableHeader) => item.value);
          break;
        case 'LogbooksComponent':
          this.tabsToBeAtTheEnd = this.logbookTableHeaders.filter((item: ITableHeader) => item.value);
          break;
        case 'AllLogbookVersionComponent':
          this.tabsToBeAtTheEnd = this.allLogbookVersionsTableHeaders.filter((item: ITableHeader) => item.value);
          break;
        case 'FormsComponent':
          this.tabsToBeAtTheEnd = this.formTableHeaders.filter((item: ITableHeader) => item.value);
          break;
      }
      return;
    }

    this.tabsToBeAtTheEnd = [];
    this.tabs[0].table = [];
    let checkedItemIndex: number = -1;
    if (this.isUserHomeConfigurationModal && configurationHeaderDefault) {
      headerDefaults.forEach((tab, index) => {
        const isFileField: boolean =
          tab.value.substring(0, this.formEntriesService.fileDynamicColumnPrefix.length) ===
          this.formEntriesService.fileDynamicColumnPrefix;
        checkedItemIndex =
          configurationHeaderDefault?.findIndex((item: Partial<ITableHeader>) => item.value === tab.value) ?? -1;

        if (checkedItemIndex !== -1 && tab.value) {
          if (isFileField) {
            this.tabs[0].table[checkedItemIndex] = {
              ...tab,
              name: `${tab.name} (${this.translate.instant('pageConfiguration.modal.labels.file')})`,
              selected: true,
            };
          } else {
            this.tabs[0].table[checkedItemIndex] = { ...tab, selected: true };
          }
        } else if (checkedItemIndex === -1 && tab.value) {
          this.tabsToBeAtTheEnd.push({
            ...tab,
            selected:
              configurationHeaderDefault && !headerDefaults[index].disabled ? false : headerDefaults[index].selected,
          });
        }
      });
    } else {
      headerDefaults.forEach((tab: ITableHeader, index: number): void => {
        if (userConfigurationData) {
          checkedItemIndex =
            userConfigurationData[page]?.findIndex((item: Partial<ITableHeader>) => item.value === tab.value) ?? -1;
        }

        if (checkedItemIndex !== -1 && tab.value) {
          this.tabs[0].table[checkedItemIndex] = { ...tab, selected: true };
        } else if (checkedItemIndex === -1 && tab.value) {
          this.tabsToBeAtTheEnd.push({
            ...tab,
            selected: userConfigurationData && !headerDefaults[index].disabled ? false : headerDefaults[index].selected,
          });
        }
      });
    }
    const newOrderedTable: ITableHeader[] = [];
    this.tabs[0].table.forEach((item) => {
      newOrderedTable.push(item);
    });
    this.tabs[0].table = newOrderedTable;
  }

  public static checkLinkedCheckbox(clickedItemName: string, data: ITableHeader[]): void {
    const clickedItem = _.find(data, { value: clickedItemName });
    const linkedTo = clickedItem && 'linkedTo' in clickedItem ? clickedItem?.['linkedTo'] : false;

    if (clickedItem?.linkedTo) {
      const checkbox: HTMLInputElement | undefined = _.head(
        document.getElementById(`table-config-checkbox-${linkedTo}`)?.getElementsByTagName('input'),
      );
      checkbox?.click();
    }
  }

  public resetParameters(): void {
    this.tabsToBeAtTheEnd = [];
    this.tabs[0].table = [];
    this.rows = [];
    this.generalConfigurationArray = [];
    this.formEntriesColumnLoaded$ = false;
  }

  public goToMyReport(): void {
    window.location.href = '#/reports/my-form-entry-reports';
  }

  public onScrollEvent = (event: Event): void => {
    const srcElement: HTMLElement = event.srcElement as HTMLElement;

    if (srcElement.classList?.contains('popover-body')) {
      return;
    }

    const popupBody: HTMLCollectionOf<Element> = document.getElementsByClassName(
      'popover fade ng-star-inserted show bs-popover-top',
    );

    if (popupBody && popupBody.length) {
      const showFormButtonElement: HTMLElement | null = document.getElementById(
        `show-form-button-${this.clickedButtonId}`,
      );
      showFormButtonElement?.click();
    }
  };

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['isChangeLogbookVersion']?.currentValue) {
      this.formEntriesColumnLoaded$ = false;
    }
  }

  public ngOnDestroy(): void {
    window.removeEventListener('scroll', this.onScrollEvent, true);
    this.modalArray.forEach((modal: NgbModalRef) => {
      modal.close();
    });
    this.storeSubscriptions.forEach((item: Subscription) => {
      item.unsubscribe();
    });

    this.disabledSubscription?.unsubscribe();
  }

  public selectOrUnselectAll(isSelectAll: boolean) {
    if (document.querySelectorAll('[id^="table-config-checkbox-"]') !== null) {
      this.isSelectOrUnselectAllClicked = true;

      const disabledElements: string[] = this.tabs[0].table
        .filter((value: ITableHeader) => value.disabled)
        .map((value: ITableHeader) => value.value);

      this.tabs[0].table.forEach((item: ITableHeader) => {
        const checkbox: HTMLInputElement | undefined = _.head(
          document.getElementById(`table-config-checkbox-${item.value}`)?.getElementsByTagName('input'),
        );

        if (checkbox && ((isSelectAll && !checkbox.checked) || (!isSelectAll && checkbox.checked))) {
          checkbox.click();
        }
      });

      if (!isSelectAll) {
        this.selectedRecords = [...disabledElements];
      }
    }

    this.isSelectOrUnselectAllClicked = false;
  }

  public closeSaveModal(): void {
    if (this.saveChangedModalRef) {
      this.saveChangedModalRef.close();
    }
  }

  public applySetAsDefault(
    setAsDefault: boolean,
    headers: ITableHeader[],
    insertToFirst: boolean,
    componentNamesForUserConfiguration: ComponentNamesForUserConfiguration,
    storeName: string,
    logbookId?: number,
  ): void {
    if (setAsDefault) {
      this.helperService.onApplyClick(
        _.cloneDeep(this.tabs[0].table),
        headers,
        insertToFirst,
        setAsDefault,
        componentNamesForUserConfiguration,
        this.userId$,
        storeName,
        logbookId,
      );
    }
  }

  public onKeyUpSearchBox(searchText: string): void {
    this.changesMade = true;
    this.generateTableConfiguration(searchText);
  }
}
